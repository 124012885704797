.icon-button {
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  user-select: none;
}

.border {
  border: var(--border-in-light);
}

.icon-button:hover {
  filter: brightness(0.9);
  border-color: var(--primary);
}

.icon-button-icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .icon-button {
    padding: 16px;
  }
}

@mixin dark-button {
  div:not(:global(.no-dark))>.icon-button-icon {
    filter: invert(0.5);
  }

  .icon-button:hover {
    filter: brightness(1.2);
  }
}

:global(.dark) {
  @include dark-button;
}

@media (prefers-color-scheme: dark) {
  @include dark-button;
}

.icon-button-text {
  margin-left: 5px;
  font-size: 12px;
}