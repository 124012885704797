@import "./window.scss";

.settings {
  padding: 20px;
  overflow: auto;
}

.settings-title {
  font-size: 14px;
  font-weight: bolder;
}

.settings-sub-title {
  font-size: 12px;
  font-weight: normal;
}

.avatar {
  cursor: pointer;
}

.logout {
  border: var(--border-in-light);
  padding: 7px 14px;
}