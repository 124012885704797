@import "./window.scss";

@mixin container {
  background-color: var(--white);
  border: var(--border-in-light);
  border-radius: 20px;
  box-shadow: var(--shadow);
  color: var(--black);
  background-color: var(--white);
  min-width: 600px;
  min-height: 480px;
  max-width: 900px;

  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  width: var(--window-width);
  height: var(--window-height);
}

.container {
  @include container();
}

@media only screen and (min-width: 600px) {
  .tight-container {
    --window-width: 100vw;
    --window-height: 100vh;
    --window-content-width: calc(100% - var(--sidebar-width));

    @include container();

    max-width: 100vw;
    max-height: 100vh;

    border-radius: 0;
  }
}

.sidebar {
  top: 0;
  width: var(--sidebar-width);
  box-sizing: border-box;
  padding: 20px;
  background-color: var(--second);
  display: flex;
  flex-direction: column;
  box-shadow: inset -2px 0px 2px 0px rgb(0, 0, 0, 0.05);
}

.window-content {
  width: var(--window-content-width);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile {
  display: none;
}

.pc {
  display: block;
}

.input-title {
  input{
    max-height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    min-height: unset;
    min-width: unset;
    max-height: unset;
    min-width: unset;
    border: 0;
    border-radius: 0;
  }

  .sidebar {
    position: absolute;
    left: -100%;
    z-index: 999;
    height: 100vh;
    transition: all ease 0.3s;
    box-shadow: none;
  }

  .sidebar-show {
    left: 0;
  }

  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }
}

.sidebar-header {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar-logo {
  position: absolute;
  right: 0;
  bottom: 18px;
}

.sidebar-title {
  font-size: 20px;
  font-weight: bold;
}

.sidebar-sub-title {
  font-size: 12px;
  font-weight: 400px;
}

.sidebar-body {
  flex: 1;
  overflow: auto;
}

.chat-list {}

.chat-item {
  padding: 10px 14px;
  background-color: var(--white);
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
  position: relative;
  overflow: hidden;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.chat-item:hover {
  background-color: var(--hover-color);
}

.chat-item-selected {
  border-color: var(--primary);
}

.chat-item-title {
  font-size: 14px;
  font-weight: bolder;
  display: block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-item-delete {
  position: absolute;
  top: 12px;
  right: -20px;
  transition: all ease 0.3s;
  opacity: 0;
}

.chat-item:hover>.chat-item-delete {
  opacity: 0.5;
  right: 10px;
}

.chat-item:hover>.chat-item-delete:hover {
  opacity: 1;
}

.chat-item-info {
  display: flex;
  justify-content: space-between;
  color: rgb(166, 166, 166);
  font-size: 12px;
  margin-top: 8px;
}

.chat-item-count {}

.chat-item-date {}

.sidebar-tail {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.sidebar-actions {
  display: inline-flex;
}

.sidebar-prompt-store {
  margin-top: 0.5rem;
}

.sidebar-action:not(:last-child) {
  margin-right: 15px;
}

.chat {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.chat-body {
  flex: 1;
  overflow: auto;
  padding: 20px;
  margin-bottom: 100px;
}

.chat-message {
  display: flex;
  flex-direction: row;
}

.chat-message-user {
  display: flex;
  flex-direction: row-reverse;
}

.chat-message-container {
  max-width: var(--message-max-width);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: slide-in ease 0.3s;

  &:hover {
    .chat-message-top-actions {
      opacity: 1;
      right: 10px;
      pointer-events: all;
    }
  }
}

.chat-message-user>.chat-message-container {
  align-items: flex-end;
}

.chat-message-avatar {
  margin-top: 20px;
}

.chat-message-status {
  font-size: 12px;
  color: #aaa;
  line-height: 1.5;
  margin-top: 5px;
}

.user-avtar {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-in-light);
  box-shadow: var(--card-shadow);
  border-radius: 10px;
}

.chat-message-item {
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  font-size: 14px;
  user-select: text;
  word-break: break-word;
  border: var(--border-in-light);
  position: relative;
}

.chat-message-top-actions {
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: -26px;
  transition: all ease 0.3s;
  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: row-reverse;

  .chat-message-top-action {
    opacity: 0.5;
    color: var(--black);
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:not(:first-child) {
      margin-right: 10px;
    }
  }
}

.chat-message-user>.chat-message-container>.chat-message-item {
  background-color: var(--second);
}

.chat-message-actions {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  font-size: 12px;
}

.chat-message-action-date {
  color: #aaa;
}

.chat-input-panel {
  position: absolute;
  bottom: 20px;
  // display: flex;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.chat-input-panel-inner {
  display: flex;
  flex: 1;
}

.chat-input {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: var(--border-in-light);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.03);
  background-color: var(--white);
  color: var(--black);
  font-family: inherit;
  padding: 10px 14px;
  resize: none;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .chat-input {
    font-size: 16px;
  }
}

.chat-input:focus {
  border: 1px solid var(--primary);
}

.chat-input-send {
  background-color: var(--primary);
  color: white;

  position: absolute;
  right: 30px;
  bottom: 10px;
}

.export-content {
  white-space: break-spaces;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.line-measure {
  width: 97%;
  margin: auto;
  padding-bottom: 6px;
}